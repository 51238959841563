body.popup {
    background-color: #f4f4f4;
}

.popup-container {
    margin: 30px;
}

.btn-success {
    background-color: #00b852;
    color: #fff;
    border-color: #00b852;
}

.tab-pane .popup-container {
    margin: 0;
}

.popup-container > .profile-details {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    padding: 20px 30px;
    border-radius: 2px;
}
