// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Inspinia
@import "./inspinia.scss";

// Font awesome
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

// Animate
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css");

// Core
@import "./style.scss";

// Layouts
@import "./layouts/popup.scss";
