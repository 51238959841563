body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: #e8e8e8;
    color: #333;
    -webkit-font-smoothing: antialiased;
    position: relative;
}

body.index {
    background-color: #fff;
    min-height: 100vh;
}

a,
button {
    cursor: pointer;
}

.text-muted {
    color: #666;
    font-size: 12px;
}

.label,
.badge,
input,
textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.page-heading {
    padding: 30px;
    margin-top: 20px;
    border: 1px solid #dcdcdc;
    border-radius: 4px 4px 0 0;
}

.page-heading-simple {
    padding: 30px 0 0;
    margin-top: 0;
    border: none;
}

.page-heading h1,
.page-heading h2 {
    margin: 0;
    font-weight: 700;
}

.page-heading h1 .fa,
.page-heading h2 .fa {
    color: $dark-blue;
}

.page-heading p {
    margin-bottom: 0;
}

.tag-list {
    padding: 0;
}

.page-heading-options {
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-top: none;
    padding: 15px 20px;
    border-radius: 0 0 4px 4px;
}

.page-heading-options ul {
    margin: 0 -20px;
}

.page-heading-options li {
    border-right: 1px solid #e1e1e1;
    padding: 0 20px;
    margin-right: -5px;
}

.page-heading-options li:last-child {
    border-right: 0;
}

.page-heading-options a {
}

.page-heading-options a .fa {
    color: $dark-blue;
}

.profile-cta-buttons {

}

.img-navbar {
    position: relative;
}


.footer {
    margin: 30px 0 0;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 12px;
    text-align: center;
    clear: both;
    position: relative;
    background-color: transparent;
    color: #999;
    border-top: 1px solid #dcdcdc;
}

.index-page {
    margin-top: 20px;
    padding: 200px 0;
}

@media (max-width: 768px) {
    .index-page {
        padding: 100px 0;
    }
}

.index-page .text-muted.text-center {
    margin-bottom: 0;
}

.ibox-title {
    padding: 20px 20px 0;
    background-color: transparent;
}

.ibox-title .form-group {
    margin: 0;
}

.ibox-content {
    padding: 20px;
    border-top: 0;
    background-color: transparent;
}

.ibox-list {
    padding: 0;
    margin: -10px 0 -15px;
    list-style: none;
}

.ibox-list li {
    padding-top: 15px;
    padding-bottom: 15px;
}

.ibox-list li:hover {
    border-radius: 4px;
    background-color: #f9f9f9;
}

.input-select {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 3px;
    color: inherit;
    display: block;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
    font-size: 14px;
    position: relative;
}

.input-select::after {
    display: block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 12px;
}

.input-select:focus,
.input-select:active {
    border-color: $dark-blue !important;
}

.input-select select {
    background: transparent;
    width: 100%;
    padding: 6px 28px 6px 12px;
    font-size: 14px;
    color: #222;
    border: 0;
    border-radius: 0;
    height: 32px;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.note-editor {
    border: 1px solid #e5e6e7;
}

.monthly-blue-bg {
    background-color: $dark-blue;
}

.primary-color-bg {
    background-color: $primary-color;
}

.top-navigation .navbar-brand {
    background-color: $dark-blue;
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.navbar {
    z-index: 1000;
    position: relative;
}
.top-navigation .navbar-nav.navbar-right {
    border-right: 0;
}

.top-navigation .navbar-nav.navbar-right .text-visible-xs {
    display: none;
}

.navbar-toggle {
    background-color: rgba(0, 0, 0, .4);
}


@media (min-width: 768px) and (max-width: 1010px) {
    .navbar-nav .hidden-sm {
        display: none;
    }
}

@media (min-width: 768px) {
    .top-navigation .navbar-nav {
        border-right: 1px solid rgba(255, 255, 255, .1);
    }

    .top-navigation .navbar-nav.navbar-right > li > a i {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .top-navigation .navbar-nav.navbar-right {
        border-top: 1px solid rgba(255, 255, 255, .1);
    }

    .navbar-header {
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

    .navbar-nav {
        margin: 0 -15px;
    }

    .navbar-collapse {
        border-top: 0;
    }

    .top-navigation .navbar-nav.navbar-right .text-visible-xs {
        display: inline;
    }
}

.navbar-static-top {
    background-color: $dark-blue;
}

.btn-primary {
    background-color: $primary-color;
    border-color: $primary-color;
    font-weight: bold;
    transition: all .3s;
}

.btn-primary:hover {
    background-color: $dark-blue;
    border-color: $dark-blue;
}

.btn-default {
    border-color: #dcdcdc;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    background-color: #f4f4f4;
}

.btn-default.active {
    background-color: #dcdcdc;
    box-shadow: none;
}

.btn.btn-text,
.btn.btn-text:hover,
.btn.btn-text:focus,
.btn.btn-text:active,
.btn.btn-text.active {
    box-shadow: none !important;
    cursor: text !important;
    padding: 6px 0 !important;
}

.top-navigation .nav-tabs > li.active {
    background: none;
}

.top-navigation .navbar .navbar-nav .dropdown-menu {
    background-color: #024;
    border: 0;
    box-shadow: 0 2px 2px rgba(0, 51, 102, .075);
    min-width: 300px;
    padding: 5px 0;
    border-radius: 0 0 2px 2px;
}

.top-navigation .navbar .dropdown-menu > li.divider {
    margin: 5px 0;
    background-color: rgba(255, 255, 255, .1);
}

.top-navigation .navbar .dropdown-menu > li:last-child > a {
    border-bottom: 0;
}

.top-navigation .navbar .dropdown-menu > li > a {
    margin: 0;
    padding: 8px 20px !important;
    border-radius: 0 !important;
    color: #fff;
}

.top-navigation .navbar .dropdown-menu > li > a .fa {
    margin-right: 5px;
}

.top-navigation .navbar .dropdown-menu > li > a:hover,
.top-navigation .navbar .dropdown-menu > li > a:focus,
.top-navigation .navbar .dropdown-menu > .active > a {
    background-color: rgba(0, 0, 0, .25);
    color: #fff !important;
}

a,
.top-navigation .nav-tabs > li.active > a,
.top-navigation .navbar-nav .dropdown-menu > .active > a,
.dropdown-menu > li > a,
.top-navigation .navbar-nav > li.open > a {
    color: $primary-color;
    transition: all .3s;
}

a:hover,
.top-navigation .navbar-nav > li > a:hover,
.top-navigation .nav-tabs > li > a:hover,
.dropdown-menu > li > a:hover,
.top-navigation .nav > li a:hover {
    color: $dark-blue;
}

.top-navigation .navbar-nav > li.open > a,
.top-navigation .navbar-nav > li > a:hover,
.top-navigation .navbar-nav > li > a:focus {
    color: #fff;
    background-color: #024;
}

@media (max-width: 768px) {
    .dropdown-menu > li > a {
        color: #fff;
    }

    .top-navigation .navbar-nav .dropdown-menu {
        border: 0;
        box-shadow: 0;
        margin-top: 0;
    }

    .top-navigation .navbar-nav .open .dropdown-menu > li > a {
        padding: 10px 15px 10px 40px;
    }

    .nav-search {
        /*width: 80%;*/
        margin: auto;
    }
}

.top-navigation .navbar-nav > li > a {
    color: #fff;
}

.nav-search .navbar-brand {
    margin: -10px 0;
    height: 55px;
    padding: 17px 25px 18px;
    border: 0;
    background-color: #f4f4f4;
    opacity: 0;
    transition: opacity 1s;
    border-right: 1px solid #dcdcdc;
}

.nav-search.affix .navbar-brand {
    opacity: 1;
}

.nav-search .fa {
    position: relative;
    top: -0.5px;
}

.advanced-search {
    display: none;
    background-color: #fff;
    margin: 0 0 20px;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 51, 102, .2);
    box-shadow: 0 1px 3px rgba(0, 51, 102, .075);
}

.index-search .advanced-search {
    display: none;
    background-color: #fff;
    margin: 10px 0 0;
    padding: 20px 0;
    border: 1px solid rgba(0, 51, 102, .15);
    box-shadow: none;
}

.advanced-search.opened {
    display: block;
}

@media (max-width: 991px) {
    .advanced-search {
        padding: 20px;
    }
}

.form-horizontal .control-label {
    padding-top: 9px;
}

.tasks-dropdown-content {
    display: none;
}

.to-do {
    margin: 0;
    padding: 0;
    list-style: none;
}

.to-do li {
    border-bottom: 1px solid rgba(0, 51, 102, .05);
}

.to-do li:last-child {
    border-bottom: 0;
}

.to-do li a {
    padding: 12px 0;
    display: block;
    position: relative;
}

.to-do li a .progress {
    margin: 5px 0 0;
    height: 16px;
}

.to-do li a .progress-bar {
    font-size: 10px;
    line-height: 16px;
    background-color: #F4AB58;
}

.to-do li.to-do-done {
    padding: 12px 0;
    color: #999;
    text-decoration: line-through;
}

.to-do li.to-do-more a {
    display: block;
}

.to-do li .fa {
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-right: 3px;
    color: rgba(0, 51, 102, .5);
}

.to-do li a .fa {
    color: rgba(0, 51, 102, .5);
}

.tasks-dropdown-title {
    font-size: 11px;
    display: block;
    margin-bottom: 5px;
    color: #222;
    text-transform: uppercase;
}

.tasks-dropdown > a:hover {
    color: #fff;
}

@media (max-width: 767px) {
    .tasks-dropdown-content {
        display: none;
        background-color: #fff;
        width: 100vw;
        padding: 20px;
        border-bottom: 1px solid rgba(0, 51, 102, .2);
        box-shadow: 0 3px 3px rgba(0, 51, 102, .075);
    }

    .tasks-dropdown-content .to-do-padding {
        padding-bottom: 40px;
    }
}

@media (min-width: 768px) {
    .tasks-dropdown {
        position: relative;
    }

    .tasks-dropdown-content {
        display: none;
        position: absolute;
        left: -80px;
        top: 50px;
        background-color: #fff;
        width: 100vw;
        padding: 50px;
        border-bottom: 1px solid rgba(0, 51, 102, .2);
        box-shadow: 0 3px 3px rgba(0, 51, 102, .075);
    }

    .tasks-dropdown > a.active,
    .tasks-dropdown > a.active:hover {
        background-color: #fff !important;
        color: $dark-blue !important;
    }

    .tasks-dropdown-content .to-do {
        margin: -12px 0;
    }
}

.mail-body .form-group {
    margin-bottom: 20px;
}

.mail-box-header {
    padding: 20px;
}

.mail-box-header h2 {
    margin-bottom: 0;
}

.ibox {
    margin-bottom: 0;
    background-color: #fff;
    border-color: #dcdcdc;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
}

.page-heading-shadow {

}

.tabs-container .panel-body {
    border-color: #dcdcdc;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    border-radius: 0 0 4px 4px;
    padding: 30px;
}

.tabs-container .nav-tabs {
    border-color: #dcdcdc;
    position: relative;
    z-index: 10;
}

.tabs-container .nav-tabs > li.active > a,
.tabs-container .nav-tabs > li.active > a:hover {
    border-color: #dcdcdc;
    border-bottom-color: #fff;
}

.tabs-container .tab-content {
    position: relative;
    z-index: 9;
}

.login-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.login-screen .navbar-brand {
    display: block;
    height: 55px;
    padding: 17px 20px 18px;
    width: 69px;
    border: 0;
    background-color: rgba(0, 0, 0, .05);
    transition: opacity 1s;
    position: absolute;
    top: -55px;
    left: 15px;
    -webkit-border-top-left-radius: 2px;
    -webkit-border-top-right-radius: 2px;
    -moz-border-radius-topleft: 2px;
    -moz-border-radius-topright: 2px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.login-screen .white-bg {
    -webkit-border-radius: 4px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: 4px;
    -moz-border-radius-topleft: 0;
    border-radius: 4px;
    border-top-left-radius: 0;
}

.index-header {

}

.index-header h1 {
    padding: 0;
    margin: 0;
}

.index-search {
    padding: 20px 0;
}

.index-widgets {
    padding: 40px 0 0;
}

.index-widget {
    border-radius: 4px;
    padding: 30px;
    position: relative;
}

.index-widget p {
    font-size: 11px;
    margin: 20px 0 0;
}

.index-widget-tasks {
    background-color: #EA5565;
    color: #fff;
    /*text-align: center;*/
}

.index-widget-processes {
    background-color: #F4AB58;
    color: #fff;
}

.index-widget-activity {
    background-color: #f9f9f9;
}

.index-widget-chart {
    padding: 49px 0;
}

.index-widget-tasks p,
.index-widget-processes p {
    color: rgba(255, 255, 255, .65)
}

.index-widget .fa {
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 24px;
}

.index-widget-count {
    font-size: 48px;
    display: block;
    letter-spacing: -4px;
}

.index-widget-title {
    font-size: 12px;
    text-transform: uppercase;
}

/* Calendar */
.fc-time {
    display: none;
}
td.fc-widget-header {
    font-size: 10px;
    text-transform: uppercase;
    border-width: 0 1px 0 1px !important;
    border-color: #fff !important;
}

th.fc-widget-header {
    border-color: #fff !important;
    padding: 0 10px 10px !important;
    text-align: right !important;
}

td.fc-day-number {
    padding: 10px 10px 5px !important;
}

.fc-event-container {
    padding: 0 6px !important;
}

.fc-day-grid-event {
    padding: 1px 5px !important;
}

.more-filters {
    display: none;
}

.more-filters.opened {
    display: block;
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    padding-top: 8px;
    line-height: 21px;
}

.photo-list,
.icon-list,
.text-list {
    list-style: none;
    margin: -25px 0;
    padding: 0;
}

.photo-list > li,
.icon-list > li,
.text-list > li {
    padding: 25px 0;
    border-bottom: 1px solid #dcdcdc;
}

.photo-list > li:last-child,
.icon-list > li:last-child,
.text-list > li:last-child {
    border-bottom: 0;
}

.list-features {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-features li {
    padding: 8px 0;
    border-bottom: 1px solid #f4f4f4;
}

.list-tools {
    margin: 10px 0;
    padding: 0;
    list-style: none;
    text-align: right;
}

.list-tools li {
    display: inline-block;
}

.icon-list-icon {
    font-size: 40px;
    color: #ef7102;
}


.table thead th {
    font-size: 10px;
    text-transform: uppercase;
}

.label-default {
    background-color: #f1f1f1;
    color: #000;
}

.tag-list li a {
    font-size: 11px;
    background-color: #fff;
    color: $primary-color;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    margin-right: 5px;
    margin-top: 5px;
    padding: 5px 12px;
    height: 27px;
    display: block;
}

.tag-list-manage li a {
    padding: 0 0 0 12px;
}

.tag-list li a:hover {
    background-color: #f4f4f4;
    color: $dark-blue;
}

.js-remove-tag {
    border-left: 1px solid #dcdcdc;
    padding: 0 10px;
    height: 25px;
    line-height: 25px;
    margin: 0 0 0 5px;
}

.js-remove-tag:hover {
    background-color: #dcdcdc;
}

.text-muted .fa {
    color: $dark-blue;
}

#page-wrapper {
    min-height: 1px;
}

.ibox-title {
    border-top: 0;
    border-bottom: 1px solid #f4f4f4;
    padding: 15px 20px;
}

.ibox-title::after {
    display: block;
    content: "";
    clear: both;
}

.ibox-title h1 {
    font-size: 22px;
}

.ibox-title h2 {
    font-size: 18px;
}

.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
    margin: 0;
    line-height: 34px;
}

.ibox-tools {
    line-height: 34px;
}

.float-e-margins .btn {
    margin-bottom: 0;
}

.ibox-content.ibox-heading {
    padding: 10px 20px;
    background-color: #e9f4fa;
    color: $dark-blue;
}

.ibox-content .advanced-search {
    margin: 0 -20px;
    padding: 0 20px 20px;
    box-shadow: none;
    border-bottom: 1px solid #f4f4f4;
}

.ibox-content .btns-wrapper {
    margin: 0 -20px 20px;
    padding: 20px;
    border-bottom: 1px solid #f4f4f4;
}

.ibox-content .table {
    margin-bottom: 0;
}

.list-group {
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    border-radius: 4px;
}

.list-group .list-group-item {
    color: $primary-color;
    border-color: #dcdcdc;
}

.list-group .list-group-item:first-child {
    border-radius: 4px 4px 0 0;
}

.list-group .list-group-item:last-child {
    border-radius: 0 0 4px 4px;
}

.list-group .list-group-item:hover {
    color: $dark-blue;
}


.customer-list {
    list-style: none;
    padding: 0;
}

.customer-card {
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    padding: 20px;
    margin-bottom: 60px;
}

@media (min-width: 768px) {
    .customer-card {
        min-height: 300px;
    }

    .customer-card--owner {
        min-height: 375px;
    }
}

.customer-card p {
    margin: 0;
}

p.customer-actions {
    margin-top: 10px;
}

.customer-info-inline {
    list-style: none;
    padding: 0;
    margin: 0;
}

.customer-info-inline li {
    display: inline-block;
}

.customer-photo {
    width: 50px;
    position: absolute;
    left: 50%;
    top: -25px;
    margin-left: -25px;
    z-index: 15;
}

.customer-photo img {
    width: 50px;
}

.customer-header {
    text-align: center;
    padding: 20px 0;
    height: 120px;
}

.customer-header h2 a {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.customer-header h2 {
    margin: 0 0 5px;
    font-size: 16px;
    font-weight: bold;
    display: block;
}

.customer-links {
    background-color: rgba(0, 0, 0, .015);
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    list-style: none;
    padding: 20px;
    margin: 0 -20px 20px;
}

.customer-links ul {
    margin-top: -5px;
    margin-bottom: -5px;
}

.customer-links li {
    margin: 5px 0;
}

.customer-links .fa {
    color: #666;
}

.customer-small {
    font-size: 11px;
    margin: 0;
}

.ibox {
    border-radius: 4px;
    border: 1px solid #dcdcdc;
}

.pagination-wrapper {
    border-top: 1px solid #dcdcdc;
    margin: 20px -20px 0;
    padding: 20px 0 0;
    text-align: center;
}

.pagination {
    margin: 0;
}

.gallery-list {
    border-top: 1px solid #dcdcdc;
    margin: 0;
    padding: 10px;
    list-style: none;
}

.gallery-title::after,
.gallery-list::after {
    content: "";
    display: block;
    clear: both;
}

.gallery-list > li {
    float: left;
    width: 50%;
}

.gallery-list li img {
    width: 100%;
    border-radius: 3px;
}

.gallery-image {
    position: relative;
    padding: 10px;
}

.gallery-image > a {
    display: block;
}

.gallery-image-btns {
    display: none;
    position: absolute;
    width: 60%;
    left: 50%;
    margin-left: -30%;
    text-align: center;
    background-color: #fff;
    border: 1px solid #ccc;
    font-size: 16px;
    border-radius: 4px;
    padding: 5px 0;
    bottom: -20px;
    z-index: 99;
}

.gallery-image-btns::after,
.gallery-image-btns::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.gallery-image-btns::after {
    border-color: rgba(249, 249, 249, 0);
    border-bottom-color: #fff;
    border-width: 5px;
    margin-left: -5px;
}
.gallery-image-btns::before {
    border-color: rgba(226, 226, 226, 0);
    border-bottom-color: #ccc;
    border-width: 6px;
    margin-left: -6px;
}

.gallery-image:hover .gallery-image-btns {
    display: block;
}

.gallery-image-btns li {

}

.gallery {
    border-radius: 4px;
    margin-bottom: 30px;
    border: 1px solid #dcdcdc;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
}

.gallery:last-child {
    margin-bottom: 0;
}

.gallery-title {
    clear: both;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
}

.gallery-title h2 {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
}

.gallery-title h2 span {
    font-weight: bold;
}

@media (min-width: 768px) {
    .gallery-list > li {
        width: 33.33333%;
    }
}

@media (min-width: 991px) {
    .gallery-list > li {
        width: 20%;
    }

    .container-fluid {
        padding: 0 30px;
    }
}

@media (min-width: 1200px) {
    .gallery-list > li {
        width: 10%;
    }
}

.request-icons {
    font-size: 16px;
}

.property-results {
    list-style: none;
    padding: 30px 0 0;
}

.property {
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 30px;
}

.property-image {
    display: block;
    position: relative;
    height: 250px;
    background-size: cover;
    background-position: center;
    border-radius: 3px 3px 0 0;
}

.property-price {
    position: absolute;
    bottom: 20px;
    background-color: rgba(0, 51, 102, .9);
    font-size: 22px;
    padding: 10px 20px;
    font-weight: bold;
    color: #fff;
    border-radius: 0 3px 3px 0
}

.property-content {
    padding: 20px;
}

@media (min-width: 768px) {
    .property-content {
        min-height: 390px;
    }
}

@media (min-width: 1200px) {
    .property-content {
        min-height: 375px;
    }
}

.property-content h2 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.property-district {
    color: #444;
    font-size: 12px;
    margin: 0 0 20px;
}

.property-address,
.property-available {
    margin: 0 -20px;
    padding: 10px 20px;
    border-top: 1px solid #f4f4f4;
}

.property-address span,
.property-available span,
.property-details span {
    font-size: 10px;
    display: block;
    text-transform: uppercase;
    margin-bottom: 3px;
    color: #666;
}

.property-details {
    list-style: none;
    padding: 0;
    margin: 0 -20px 20px;
    border-top: 1px solid #f4f4f4;
}

.property-details li {
    border-right: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    padding: 10px 20px;
}

.property-details li:nth-child(even) {
    border-right: 0;
}

.property-details li:nth-child(5n+5),
.property-details li:nth-child(6n+6) {
    border-bottom: 0;
}

.property-utils {
    font-size: 11px;
    margin: 0;
}


.dropdown-submenu {
    position: relative;
}

.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px !important;
    margin-left: -5px;
    border: 1px solid rgba(0, 51, 102, .1);
    border-top: 1px solid rgba(0, 51, 102, .1) !important;
}

.dropdown-submenu.active > .dropdown-menu {
    display: block;
}

.dropdown-submenu > a::after {
    position: absolute;
    display: block;
    content: " ";
    right: 20px;
    top: 50%;
    margin-top: -5px;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-right: 0;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
}

.dropdown-submenu > .dropdown-menu::after,
.dropdown-submenu > .dropdown-menu::before {
    display: none;
}

.feed-element label,
.feed-element .input-select {
    display: inline-block;
    width: auto;
    margin-right: 10px;
}

input.datepicker {
    border-radius: 0;
    padding: 6px 12px;
}

div.datepicker {
    z-index: 999999 !important;
}

/*Form Wizard*/
.bs-wizard-wrapper {border-bottom: solid 1px #f4f4f4; padding: 0 0 20px;margin-bottom: 20px;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #555; font-size: 14px; line-height: 21px; margin-bottom: 5px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 30px; height: 30px; display: block; background: $primary-color; top: 45px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 14px; height: 14px; background: #a40; border-radius: 50px; position: absolute; top: 8px; left: 8px; }
.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 8px; box-shadow: none; margin: 20px 0;background-color: #f4f4f4;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: $primary-color;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f4f4f4;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }
/*END Form Wizard*/

.contract-status {

}

.contract-status .control-label {
    margin: 0;
    line-height: 34px;
}

@media (min-width: 768px) {
    .contract-status-label {
        text-align: right;
    }
}

@media (max-width: 767px) {
    .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
        visibility: hidden;
    }

    .bs-wizard > .bs-wizard-step {
        margin-top: -21px;
    }
}

.radio-inline input[type="radio"] {
    margin-top: 2px;
}

.radio-inline .label {
    position: relative;
    top: -1px;
    margin-left: 3px;
}

.list-wiki {
    margin-top: -30px;
    margin-bottom: -30px;
}

.list-wiki > li {
    margin: 30px 0;
}

.list-wiki > li hr {
    margin: 15px 0;
}

.list-wiki > li p {
    margin: 0;
}

.list-wiki .breadcrumb {
    font-size: 11px;
}

.breadcrumb {
    background: none;
}

.list-wiki-link {
    display: block;
}

.list-wiki-link .fa-li,
.list-wiki-link p {
    color: #333;
}

.list-wiki-header {
    font-weight: 700;
    display: block;
    font-size: 14px;
}

.list-wiki-details {
    font-size: 12px;
    margin: 0 -5px;
    padding: 0;
    border-radius: 4px;
    color: #555;
}

.list-wiki-details .fa {
    color: #888;
}

.list-wiki .fa-li {
    top: 4px;
}

.ui-helper-hidden-accessible {
    display: none;
}

.contract {
    border-top: 1px solid #dcdcdc;
    padding: 30px 0;
}

#flat-contact .col-md-6:first-child .contract {
    padding: 0 0 30px;
    border-top: 0;
}

@media (min-width: 992px) {
    #flat-contact .col-md-6:nth-child(2) .contract {
        padding: 0 0 30px;
        border-top: 0;
    }
}

.ibox-contract {

}

.ibox-contract .row {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ibox-contract .contract-label {
    color: #555;
}

.ibox-contract .contract-value {

}

.ibox-contract .contract-value .fa {
    color: #999;
}

body.affixed .navbar {
    margin-bottom: 56px;
}

.js-add-tag {
    position: relative;
    top: 5px;
    padding: 3px 15px;
    height: 27px;
    border-radius: 4px;
}

.js-add-tag-form {
    float: left;
    max-width: 50%;
    display: inline-block;
    text-align: right;
}

.js-add-tag-form .form-control {
    height: 27px;
    font-size: 12px;
    position: relative;
    top: 5px;
    line-height: 15px;
}

.tab-loading {
    position: relative;
}

.tab-loading::after {
    display: block;
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center top 40px;
    background-image: $image-loading;
    background-repeat: no-repeat;
}

.tab-remote {
    background: #fff;
    border: 1px solid #e7eaec;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    top: -1px;
}

.search-results {
    padding: 0;
    margin-bottom: -30px;
    list-style: none;
}

.search-results > li {
    margin-bottom: 30px;
}

.search-result {
    border: 1px solid #dcdcdc;
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    position: relative;
}

.search-result-type {
    position: absolute;
    top: -1px;
    right: -1px;
    padding: 10px 15px;
    background-color: #dcdcdc;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 2px;
    z-index: 10;
}

.search-result-link {
    display: block;
    padding-top: 5px;
}

.search-result-link h2 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 5px;
    height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding-right: 20px;
}

.search-result-link p {
    color: #555;
    margin: 0;
    height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.search-result .add-to-clipboard {
    margin-top: 5px;
    margin-right: 5px;
    height: 27px !important;
    padding: 0 10px !important;
}

.type-property {
    background-color: #00BFD8;
}

.type-building {
    background-color: #C3E42B;
}

.type-wiki {
    background-color: #222;
    color: #fff;
}

.type-email {
    background-color: #4842B7;
    color: #fff;
}

.type-contract {
    background-color: #FFC300;
}

.type-contact {
    background-color: #FF3A00;
    color: #fff;
}

.type-transaction {
    background-color: #607D8B;
    color: #fff;
}

.type-user {
    background-color: #FFEB3B;
}

.type-owner {
    background-color: #00B852;
}

.table .suspended {
    opacity: .4;
}

.crawler-h2 {
    float: left;
    line-height: 32px;
}

.crawler-h2 {
    padding-bottom: 10px;
}

.crawler-pagination {
    text-align: right;
    padding: 0;
    font-size: 13px;
    float: right;
}

.crawler-review {
    padding: 0 10px 5px;
    margin-bottom: 10px;
    font-size: 11px;
    border-bottom: 1px solid #f4f4f4;
}

.crawler-info {
    clear: both;
    padding: 0;
    margin: 10px 0 5px;
    list-style: none;
    font-size: 11px;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
}

.crawler-info li {
    display: inline-block;
    padding: 5px 15px 5px 10px;
    border-right: 1px solid #f4f4f4;
}

.crawler-info li:last-child {
    padding-right: 0;
    border-right: 0;
}

.crawler-footer {
    padding: 0 25px;
    color: #777;
    font-size: 11px;
}

.no-results {
    background-color: #fff;
    margin: 30px 0 10px;
    padding: 60px;
    text-align: center;
    border: 1px solid #dcdcdc;
}

.no-results-content {
    margin-top: 30px;
}

.no-results-content p {
    margin: 0;
}

.no-results-content p > strong {
    font-size: 16px;
    display: block;
    margin-bottom: 5px;
}

.form-control-static {
    padding-top: 9px !important;
}

.btn-property-search .fa {
    top: 0 !important;
    color: $dark-blue;
}

.btn-property-search .caret {
    margin-left: 3px;
    color: #999;
}

.js-nav-search-form .btn-primary .fa {
    position: relative;
    top: 0px;
}

@media (min-width: 992px) {
    .profile-right {
        border-left: 1px solid #dcdcdc;
        padding-left: 30px;
    }

}

.btn-history {
    font-size: 12px;
    padding: 5px 10px;
    position: relative;
    margin-top: -6px;
    top: 1px;
    left: 5px;
    color: #555;
}

.btn-history .fa {
    color: $primary-color;
    font-size: 16px;
    position: relative;
    top: 1px;
    margin-right: 3px;
}

.btn-history:hover,
.btn-history:hover .fa {
    color: $dark-blue;
}

.js-add-tag .fa {
    color: $primary-color;
}

.form-control {
    border-radius: 3px;
}

textarea.form-control {
    padding: 12px;
    resize: none;
}

.form-control:focus {
  border-color: $dark-blue !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary.active:focus {
  background-color: $dark-blue;
  border-color: #000;
  color: #FFFFFF;
}

input.datepicker {
    border-radius: 4px;
}

.input-group-addon {
    border-radius: 4px;
}

.input-group .input-select:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group .input-select:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.table > thead,
.table > thead th {
    font-weight: 400;
    color: #999;
}

.table > tbody td {
    border-bottom: 0;
    border-right: 0;
    vertical-align: middle;
}

.table > tbody tr td {
    border-top: 0px;
    transition: all .4s;
}

.table > thead th {
    padding: 0 20px 15px !important;
    border-bottom: none !important;
}

.table > tbody td {
    padding: 15px 20px !important;
}

.table > tbody tr:first-child td:first-child {
    border-top-left-radius: 4px;
}

.table > tbody tr:first-child td:last-child {
    border-top-right-radius: 4px;
}

.table > tbody tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
}

.table > tbody tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
}

.table-in-table > tbody td {
    border: 0 !important;
    padding: 5px 0 !important;
}

.list-group-item {
    padding: 20px;
}

.sidebar-settings.affix {
    position: fixed;
    top: 75px;
}

@media (max-width: 992px) {
    .sidebar-settings.affix {
        position: static;
    }
}

.js-nav-actions {
    font-size: 32px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    display: block;
    background-color: $primary-color;
    border-radius: 50%;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9999;
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
}

.js-nav-actions .fa {
    transition: all .3s;
}

.js-nav-actions:hover {
    background-color: $dark-blue;
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .75);
}

.opened .js-nav-actions .fa {
    position: relative;
    top: -1px;
    left: -1px;
    transform: rotate(45deg);
}

.nav-actions ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: fixed;
    bottom: 114px;
    right: 30px;
    text-align: right;
    display: none;
    z-index: 9999;
}

.nav-actions ul > li {
    line-height: 45px;
}

.nav-actions ul > li a {
    background-color: #fff;
    border: 1px solid #dcdcdc;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.nav-actions.opened ul {
    display: block;
}

.stats-list {
    list-style: none;
    padding: 0 !important;
    margin: -20px;
}

.stats-list li {
    border-bottom: 1px solid #f4f4f4;
    padding: 20px;
}

.stats-list li.col-sm-6:nth-child(2n+3) {
    border-left: 1px solid #f4f4f4;
}

.stats-chart {
    width: 100%;
    max-width: 900px;
    height: 500px;
    margin: 20px auto 0;
}

.tab-more-details {
    margin: 15px -30px -30px;
    padding: 15px 30px;
    border-top: 1px solid #dcdcdc;
}

.img-rounded {
    border-radius: 3px !important;
}

.tab-empty {
    opacity: .55;
    transition: all .3s;
}

.tab-empty.active,
.tab-empty:hover {
    opacity: 1;
}

.mail-list,
.mail-list .mail-details {
    list-style: none;
    padding: 0;
    margin: 0;
}

.mail-list {
    margin: -20px 0;
}

.mail-list > li {
    border-bottom: 1px solid #f4f4f4;
    padding: 20px 0;
}

.mail-list .mail-details {
    font-size: 12px;
    color: #777;
    margin-bottom: 5px;
}

.mail-list .mail-content {
    cursor: pointer;
}

.mail-list .mail-content p {
    margin: 0;
}

.mail-list iframe {
    width: 100%;
    border: 0;
}

.mail-list .iframe {
    border: 1px solid #f4f4f4;
    border-radius: 3px;
    margin-top: 20px;
    position: relative;
}

.mail-list .iframe::after,
.mail-list .iframe::before {
    bottom: 100%;
    left: 20px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.mail-list .iframe::after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff;
    border-width: 8px;
    margin-left: -8px;
}
.mail-list .iframe::before {
    border-color: rgba(244, 244, 244, 0);
    border-bottom-color: #f4f4f4;
    border-width: 9px;
    margin-left: -9px;
}

[v-cloak] { display:none; }

div.dim {
    filter: alpha(opacity=50); /* internet explorer */
    -khtml-opacity: 0.50;      /* khtml, old safari */
    -moz-opacity: 0.50;      /* mozilla, netscape */
    opacity: 0.50;      /* fx, safari, opera */
}

.dashboard-item {
    padding-bottom: 15px;
}